var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "取回商品",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, _vm._l(_vm.list, function (goods) {
    return _c('div', {
      key: goods.id,
      staticClass: "goods-container"
    }, [_c('settle-handle-goods', {
      staticClass: "goods",
      attrs: {
        "goods": goods,
        "show-remark": false
      }
    }), _c('van-button', {
      attrs: {
        "block": "",
        "square": "",
        "type": "info",
        "size": "small"
      },
      on: {
        "click": function click($event) {
          return _vm.handleGoodsClick(goods);
        }
      }
    }, [_vm._v("取回")])], 1);
  }), 0), _c('my-dialog', {
    attrs: {
      "title": "确认取回"
    },
    on: {
      "confirm": _vm.handleDialogConfirm
    },
    model: {
      value: _vm.showDialog,
      callback: function callback($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c('van-field', {
    attrs: {
      "label": "完好的",
      "type": "number",
      "placeholder": "请输入完好的数量"
    },
    model: {
      value: _vm.postData.good_volume,
      callback: function callback($$v) {
        _vm.$set(_vm.postData, "good_volume", $$v);
      },
      expression: "postData.good_volume"
    }
  }), _c('van-field', {
    attrs: {
      "label": "损坏的",
      "type": "number",
      "placeholder": "请输入损坏的数量"
    },
    model: {
      value: _vm.postData.bad_volume,
      callback: function callback($$v) {
        _vm.$set(_vm.postData, "bad_volume", $$v);
      },
      expression: "postData.bad_volume"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }