<template>
  <div>
    <my-nav-bar
      title="取回商品"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <div
        v-for="goods in list"
        :key="goods.id"
        class="goods-container"
      >
        <settle-handle-goods
          class="goods"
          :goods="goods"
          :show-remark="false"
        />
        <van-button block square type="info" size="small" @click="handleGoodsClick(goods)">取回</van-button>
      </div>
    </div>
    <my-dialog v-model="showDialog" title="确认取回" @confirm="handleDialogConfirm">
      <van-field v-model="postData.good_volume" label="完好的" type="number" placeholder="请输入完好的数量" />
      <van-field v-model="postData.bad_volume" label="损坏的" type="number" placeholder="请输入损坏的数量" />
    </my-dialog>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import SettleHandleGoods from './components/goods'
import { getCanRetrieve, retrieveAfterGoods } from '@/api/settle_handle'
import MyDialog from '@/components/my-dialog'
export default {
  components: { myNavBar, SettleHandleGoods, MyDialog },
  data() {
    return {
      list: [],
      postData: {
        after_order_diff_id: this.$route.query.after_order_diff_id,
        good_volume: '',
        bad_volume: ''
      },
      showDialog: false
    }
  },
  created() {
    const params = {
      after_order_diff_id: this.$route.query.after_order_diff_id
    }
    getCanRetrieve(params).then(res => {
      this.list = res.data
    })
  },
  methods: {
    handleGoodsClick(afterGoods) {
      this.postData.after_goods_id = afterGoods.id
      this.postData.good_volume = afterGoods.good_volume - afterGoods.accept_good_volume
      this.postData.bad_volume = afterGoods.bad_volume - afterGoods.accept_bad_volume
      this.showDialog = true
    },
    handleDialogConfirm() {
      this.postData.good_volume = Number(this.postData.good_volume)
      this.postData.bad_volume = Number(this.postData.bad_volume)
      retrieveAfterGoods(this.postData).then(res => {
        this.success(res.msg)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .app-main-container {
    padding: 15px 15px 5px 15px;
  }
  .goods-container {
    margin-bottom: 10px;
  }
  .goods {
    background-color: #fff;
    padding: 10px
  }
</style>
